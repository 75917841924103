import { Combobox } from "@headlessui/react"
import { StarIcon } from "@heroicons/react/24/solid"
import algoliasearch from "algoliasearch/lite"
import React, { useEffect, useState } from "react"
import { Configure, Index, InstantSearch, useHits, useSearchBox } from "react-instantsearch"
import { twMerge } from "tailwind-merge"

import { searchPractitionerWebsiteUrl } from "../../@core/practitioner/practitioner.utils"
import { CloseButton } from "../../components/shared/Buttons"
import { BASE_INPUT_CLASSNAMES, DROPDOWN_CLASSNAMES, DROPDOWN_OPTION_CLASSNAMES } from "../../components/shared/Inputs"
import Typography from "../../components/shared/Typography"

import LocationSearchBox from "./LocationSearchBox"

const searchClient = algoliasearch(
  process.env.ALGOLIA_HEALME_SEARCH_APPLICATION_ID,
  process.env.ALGOLIA_HEALME_SEARCH_CLIENT_API_KEY
)

const SearchBox = (props) => {
  const { refine } = useSearchBox()
  const urlParams = new URLSearchParams(window.location.search)
  const defaultQuery = urlParams.get("query") || ""
  const [inputValue, setInputValue] = useState(defaultQuery)
  const [googlePlacesLoaded, setGooglePlacesLoaded] = useState(false)

  const setQuery = (newQuery) => {
    setInputValue(newQuery)
    refine(newQuery)
  }

  useEffect(() => {
    const onSearchFieldReset = () => setInputValue("")
    const onGooglePlacesLoaded = () => setGooglePlacesLoaded(true)
    document.addEventListener("resetSearchField", onSearchFieldReset)
    document.addEventListener("googlePlacesLoaded", onGooglePlacesLoaded)

    return () => {
      document.removeEventListener("resetSearchField", onSearchFieldReset)
      document.removeEventListener("googlePlacesLoaded", onGooglePlacesLoaded)
    }
  }, [])

  return (
    <div className="ml-12 flex sm:hidden">
      <Combobox
        value={inputValue}
        onChange={(hit) => {
          if (hit.user) {
            window.location.href = searchPractitionerWebsiteUrl(hit)
          } else {
            setInputValue(hit)
            const event = new CustomEvent("refinementChanged", { detail: hit })
            document.dispatchEvent(event)
          }
        }}>
        <div className="relative">
          <Combobox.Input
            placeholder="Try “Massage” or “Back pain”"
            {...props}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(hit) => hit.name || inputValue || ""}
            className={twMerge(BASE_INPUT_CLASSNAMES, "w-[260px] rounded-r-none")}
          />
          {inputValue !== "" && (
            <CloseButton
              onClick={() => {
                setQuery("")
                const event = new CustomEvent("refinementChanged", { detail: { name: "" } })
                document.dispatchEvent(event)
              }}
              className="absolute right-0 top-0 mr-2 mt-[9px] h-5 w-5"
            />
          )}
        </div>
        <Combobox.Options className={twMerge(DROPDOWN_CLASSNAMES, "fixed mt-[42px] max-h-[700px] w-[520px]")}>
          {/* this is needed so you can search a custom string and not just an option from the dropdown */}
          {inputValue !== "" && (
            <Combobox.Option value={{ id: null, name: inputValue }} className="hidden">
              {inputValue}
            </Combobox.Option>
          )}
          <Index indexName="Specialty">
            <Configure hitsPerPage={6} />
            <SpecialtySearchResults />
          </Index>
          <Index indexName="Practice_production">
            <Configure hitsPerPage={3} />
            <PractitionerSearchResults />
          </Index>
        </Combobox.Options>
      </Combobox>
      {googlePlacesLoaded && <LocationSearchBox />}
    </div>
  )
}

const SpecialtySearchResults = () => {
  const { hits } = useHits()
  const therapies = hits.filter((hit) => hit.ordered_kind === 1)
  const healthConditions = hits.filter((hit) => hit.ordered_kind !== 1)

  return (
    <div>
      {therapies.length > 0 && (
        <>
          <Typography className="px-3 py-2" variant="capitalHeading">
            Therapies
          </Typography>
          {therapies.map((hit) => (
            <Combobox.Option
              key={hit.objectID}
              value={hit}
              className={({ active, selected }) =>
                twMerge("cursor-pointer px-3 py-2 font-bold", selected ? "bg-gray" : active ? "bg-gray-ultralight" : "")
              }>
              <button
                dangerouslySetInnerHTML={{
                  __html: hit._highlightResult.name.value.replace(
                    "<mark>",
                    "<mark style='font-weight: normal; color: rgb(var(--color-dark-gray))'>"
                  )
                }}
                onClick={() => {
                  const event = new CustomEvent("refinementChanged", { detail: hit })
                  document.dispatchEvent(event)
                }}
              />
            </Combobox.Option>
          ))}
        </>
      )}
      {healthConditions.length > 0 && (
        <>
          <Typography className="px-3 py-2" variant="capitalHeading">
            Health Conditions
          </Typography>
          {healthConditions.map((hit) => (
            <Combobox.Option
              key={hit.objectID}
              value={hit}
              className={({ active, selected }) =>
                twMerge("cursor-pointer px-3 py-2 font-bold", selected ? "bg-gray" : active ? "bg-gray-ultralight" : "")
              }>
              <button
                dangerouslySetInnerHTML={{
                  __html: hit._highlightResult.name.value.replace(
                    "<mark>",
                    "<mark style='font-weight: normal; color: rgb(var(--color-dark-gray))'>"
                  )
                }}
                onClick={() => {
                  const event = new CustomEvent("refinementChanged", { detail: hit })
                  document.dispatchEvent(event)
                }}
              />
            </Combobox.Option>
          ))}
        </>
      )}
    </div>
  )
}

const PractitionerSearchResults = () => {
  const { hits } = useHits()

  return (
    <div>
      <Typography className="px-3 py-2" variant="capitalHeading">
        Practitioners
      </Typography>
      {hits.map((hit) => (
        <Combobox.Option key={hit.objectID} value={hit} className={DROPDOWN_OPTION_CLASSNAMES}>
          <a className="flex items-center gap-2" href={searchPractitionerWebsiteUrl(hit)}>
            <img className="h-10 w-10 rounded-full" src={hit.user.photo} alt={hit.user.name} />
            <div>
              <div
                className="font-bold"
                dangerouslySetInnerHTML={{
                  __html: hit._highlightResult.user.name.value.replace(
                    "<mark>",
                    "<mark style='font-weight: normal; color: rgb(var(--color-dark-gray))'>"
                  )
                }}
              />
              <div className="flex items-center gap-1 font-bold">
                <StarIcon className="h-5 w-5 text-orange" />
                <span>{hit.reviews_average === 5 ? "5.0" : hits.reviews_average}</span>
                <span className="text-teal">{hit.reviews_count} reviews</span>
              </div>
            </div>
          </a>
        </Combobox.Option>
      ))}
    </div>
  )
}

const TopNavSearchBox = () => (
  <InstantSearch searchClient={searchClient} indexName="Specialty">
    <SearchBox />
  </InstantSearch>
)

export default TopNavSearchBox
